.loader-icon {
  animation: rotation 2s infinite linear;
  -webkit-animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-359deg);
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-359deg);
  }
}
